.k-colorpalette {

    table > th, td {
        border-radius: 8px;
    }

    table tr:last-child td:last-child {
        background-color: transparent;
        background: conic-gradient(white 0.25turn, Silver 0.25turn 0.5turn, white 0.5turn 0.75turn, Silver 0.75turn) top left / 50% 50% repeat;
    }

    .k-colorpalette-table {
        border-spacing: 8px;
        border-collapse: separate;

        .k-colorpalette-tile.k-state-selected {
            border: #0094ff 2px solid;
            box-shadow: none;
        }
    }
}

.color-picker {
    .k-slide-down-enter-active {
        border-radius: 8px;
    }

    .k-colorpicker {
        direction: rtl;
        width: 60px;
        background-color: transparent;
        border-color: transparent;
        background-image: none;

        &.k-focus {
            box-shadow: none;
        }
    }

    .k-input-inner {
        padding: 0;
        height: 28px;
        width: 28px;

        .k-color-preview {
            border-radius: 8px;
        }
    }

    &.transparent {
        .k-selected-color {
            background: conic-gradient(white 0.25turn, Silver 0.25turn 0.5turn, white 0.5turn 0.75turn, Silver 0.75turn) top left / 50% 50% repeat;
        }
    }

    &.disabled {
        .k-colorpicker {
            width: 30px;
        }

        button {
            display: none;
        }
    }
}
