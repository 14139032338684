.page-loader {
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0.5;
    position: fixed;
    background-color: white;
}

.loader {
    z-index: 3;
    left: 50vw;
    top: 5px;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
}