﻿@import './_variables2.scss';

/* colors */
$color-primary: #1db3dd;
$color-inactive: #4840408a;

$grid-header-row-color: #686868;
$grid-header-row-background-color: #e8e8e8;
$grid-selected-row-background-color: #0094ff;

$no-auscultation-message-content-background-color: #e8e8e8;

$dialog-border-bottom-color: #bdcfd8;

$summary-drawer-grid-header-background-color: #f6f6fe;
$input-icon-color: #858889;

$projects-step-background-color: rgba(255, 255, 255, 0.4);
$project-item-card-box-shadow: -4px 8px 24px -4px rgba(0, 0, 0, 0.16);
$project-label-background-color: rgba(0, 69, 104, 0.7);

$nav-menu-active-link-color: #FFED00;

$drawer-border-title-color: #004568;

$undetermined-color: #fff;
$unfiltered-section-color: #9CB3C9;

$work-to-do-color: #FA9E00;
$work-done-color: #1DB3DD;

$map-filter-button-selected-background-color: #1DB3DD;
/* variables */
$header-height: 48px;
$details-drawer-margin-from-header: 1px;
$user-admin-padding: 15px;
$users-grid-label-height: 24px;
$user-grid-width: 100%;
$btn-action-margin-top-bottom: 20px;
$btn-action-height: 36px;

$help-dialog-width: 490px;
$user-dialog-width: 586px;
$user-dialog-height: 348px;

$snackbar-background-success: #43a047;
$snackbar-background-warning: #ffa000;
$snackbar-background-error: #d32f2f;

// nouvel design
$font-family: 'open sans';

$opened-drawer-content-padding-height-bottom: 4px;

$hr-border: 1px;

$map-filters-content-padding: 8px;
$road-works-map-filter-button-top: 143px;

$label-score-grid-height-gap-from-window: $project-header-height + $actions-menu-height + 2px + $opened-drawer-content-padding-height-bottom + $opened-drawer-content-padding-height-bottom;
$client-label-score-grid-height-gap-from-window: $project-header-height + $actions-menu-height + 2px + $opened-drawer-content-padding-height-bottom + $opened-drawer-content-padding-height-bottom;

:export {
    labelScoreGridHeightGapFromWindow: $label-score-grid-height-gap-from-window;
    clientLabelScoreGridHeightGapFromWindow: $client-label-score-grid-height-gap-from-window;
    unfilteredSectionColor: $unfiltered-section-color;    
}
