$font-family: 'open sans';

$help-dialog-width: 490px;

$project-header-height: 36px;
$programme-header-padding-height-bottom: 3px;
$actions-menu-height: 42px;
$actions-menu-padding-height-bottom: 3px;
$actions-menu-padding-right-left: 5px;
$detection-list-filter-width: 380px;
$quality-list-filter-width: 322px;
$geography-list-filter-width: 322px;

$nav-menu-height: 75px;
$nav-menu-width: 95px;
$nav-menu-padding-top-bottom: 8px;
$nav-menu-padding-left: 2px;
$nav-menu-padding-right: 2px;

$drawer-border-right: 1px;
$opened-roads-drawer-width: 564px;
$closed-drawer-width: 55px;

//colors
$primary-dark: #054861;
$primary-base: #1DB3DD;
$primary-medium: #0A91C2;
$primary-deepdark: #032430;
$secondary-medium: #FFED00;
$secondary-light: #FFF566;
$secondary-dark: #CCBE00;
$default-base: #2B2B2B;
$contrast-clear: #DEEBF1;
$contrast-light: #F0F4F6;
$neutral-highlight: #DEE6ED;
$neutral-deepdark: #121A21;
$neutral-medium: #5B80A4;
$neutral-ultralight: #EFF2F6;
$default-light: #858889;
$default-clear: #BDCFD8;
$brand-blue: #004568;
$required-form-color: #EE7402;
$tropical-blue: #D4DAF7;
$pale-blue-lily: #D9EDF2;
$blue-romance: #D8F3DF;
$light-steel-blue: #9CB3C9;
$pumpkin: #EC7813;
$dodger-blue: #4285F4;

$selected-section-color: #121A21;
$enlightened-area-color: #121A21;

$grid-header-row-background-color: #e8e8e8;
$inactive: #4840408a;

$affaissement_de_rive-anomaly-color: #A79DFC;
$affaissement_de_rive_G1-anomaly-color: #A79DFC;
$affaissement_de_rive_G2-anomaly-color: #A79DFC;
$bordure_trottoir-anomaly-color: #FFF1AD;
$arrachement-anomaly-color: #C0CE2E;
$desenrobage_plumage-anomaly-color: #C0CE2E;
$emergence_sur_chaussee-anomaly-color: #FF69C3;
$faiençage-anomaly-color: #4E18AE;
$fissuration_multidirectionnelle-anomaly-color: #61B1B9;
$faiençage_ponte-anomaly-color: #1DB3DD;
$fiss_multi_ponte-anomaly-color: #1DB3DD;
$fissure_longitudinale-anomaly-color: #346AFA;
$fiss_long_ponte-anomaly-color: #042D99;
$fissure_transversale-anomaly-color: #AAE4F6;
$fiss_trans_ponte-anomaly-color: #76A8B8;
$fiss_ponte-anomaly-color: #688BAE;
$joint_longitudinal-anomaly-color: #A0C6FF;
$joint_longitudinal_ponte-anomaly-color: #5A92E8;
$nid_de_poule-anomaly-color: #FF000F;
$nid_de_poule_repare-anomaly-color: #FF888F;
$peignage-anomaly-color: #246605;
$pelade-anomaly-color: #4AE700;
$reparation-anomaly-color: #C77A42;
$reparation_enrobe-anomaly-color: #C77A42;
$reparation_pata-anomaly-color: #FAD000;
$ressuage-anomaly-color: #FF6B00;
$tranchee-anomaly-color: #602A03;
$mixed_anomalies_color: #848788;

$tranchee_transversale-anomaly-color: #602A03;
$joint_long_ponte-anomaly-color: #5A92E8;
$feu_de_circulation-anomaly-color: #000000;
$passage_pieton-anomaly-color: #000000;
$pontage-anomaly-color: #000000;
$tampon-anomaly-color: #000000;

$depart_granulats-color: #C0CE2E;
$fissure_multidirectionnelle-color: #61B1B9;
$fissure_multidirectionnelle_pontee-color: #1DB3DD;
$reparation_en_enrobe-color: #C77A42;

$marking--continuous--solid: #685176;
$marking--continuous--dashed: #6F7551;
$marking-only--continuous--dashed: #332E36;
$marking--continuous--zigzag: #D095F5;
$marking--discrete--arrow--right: #3083A1;
$marking--discrete--arrow--left: #B68046;
$marking--discrete--arrow--split-right-or-straight: #7BC5E0;
$marking--discrete--arrow--split-left-or-straight: #613405;
$marking--discrete--arrow--straight: #054861;
$marking--discrete--stop-line: #E8416B;
$marking--discrete--give-way-row: #E841CF;
$marking__discrete__give_way_single: #E85241;
$marking--discrete--crosswalk-zebra: #BFB630;
$marking--discrete--symbol--bicycle: #807B40;
$marking--discrete--text: #4F00FF;
$marking--discrete--hatched--chevron: #397B87;
$marking--discrete--hatched--diagonal: #B8B6A1;

$complementary-detection-color: #DDA900;
$warning-detection-color: #DE5337;
$information-detection-color: #9AF200;
$regulatory-detection-color: #1A60D4;

$monitoring-quality-color: #39C05F;
$localized-repair-quality-color: #FFEA00;
$general-maintenance-quality-color: #FA9E00;
$reinforcement-quality-color: #FA0000;
$rehabilitation-quality-color: #3F0689;
$empty-quality-color: #6D8296;

$empty-attribute-section-color: #9CB3C9;
$changed-attribute-section-color: #fde0ca;
$changed-cell-color: #FBE4D0;


$main-hierarchy-color: #33DBAA;
$local-hierarchy-color: #E36900;
$residential-hierarchy-color: #004191;

$valid_control-color: #3BC461;
$invalid_control-color: #F00F0F;

$road-section-details-padding: 5px;
$road-section-height: 24px;
$road-section-margin-bottom: 5px;
$image-height: 250px;
$combined-images-height: 500px;

$areas-sections-width: 564px;
$areas-synthesis-height: 40px;
$areas-synthesis-padding: 16px;
$add-edit-area-height: 138px;
$areas-list-height: 320px;

$road-works-information-width: 450px;
$road-works-information-padding: 10px;

$road-condition-map-content-width-with-opened-roads-drawer: calc(100vw - #{$opened-roads-drawer-width} - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right});
$road-condition-map-content-width-without-drawer: calc(100vw - #{$nav-menu-width} - #{$nav-menu-padding-left} - #{$nav-menu-padding-right} - (#{$drawer-border-right} * 2));
$road-condition-map-content-height: calc(100vh - #{$project-header-height} - #{$actions-menu-height} - 2px);

$sections-selector-padding-top-bottom: 16px;
$budget-summary-margin-bottom: 10px;
$budget-summary-height: 44px;
$btns-actions-margin-top: 20px;
$btns-actions-height: 40px;

$sections-grid-height-gap-from-window: $project-header-height + 6px + $actions-menu-height + 2px + $sections-selector-padding-top-bottom + $sections-selector-padding-top-bottom + $budget-summary-margin-bottom + $budget-summary-height + $btns-actions-margin-top + $btns-actions-height;
$areas-sections-grid-height-gap-from-window: $project-header-height + 10px + $actions-menu-height + 2px + $add-edit-area-height + 42px;

$highways-grid-height-gap-from-window: calc((100vw /2));

:export {
    emptyAttributeSectionColor: $empty-attribute-section-color;
    monitoringQualityColor: $monitoring-quality-color;
    localizedRepairQualityColor: $localized-repair-quality-color;
    generalMaintenanceQualityColor: $general-maintenance-quality-color;
    reinforcementQualityColor: $reinforcement-quality-color;
    rehabilitationQualityColor: $rehabilitation-quality-color;
    emptyQualityColor: $empty-quality-color;
    roadConditionMapContentWidthWithOpenedRoadsDrawer: $road-condition-map-content-width-with-opened-roads-drawer;
    roadConditionMapContentWidthWithoutDrawer: $road-condition-map-content-width-without-drawer;
    roadConditionMapContentHeight: $road-condition-map-content-height;
    sectionsGridHeightGapFromWindow: $sections-grid-height-gap-from-window;
    areasSectionsGridHeightGapFromWindow: $areas-sections-grid-height-gap-from-window;
    highwaysGridHeightGapFromWindow: $highways-grid-height-gap-from-window;
    areasListHeight: $areas-list-height;
    selectedSectionColor: $selected-section-color;
    enlightenedAreaColor: $enlightened-area-color;
    affaissementDeRiveAnomalyColor: $affaissement_de_rive-anomaly-color;
    affaissementDeRiveG1AnomalyColor: $affaissement_de_rive_G1-anomaly-color;
    affaissementDeRiveG2AnomalyColor: $affaissement_de_rive_G2-anomaly-color;
    bordureTrottoirAnomalyColor: $bordure_trottoir-anomaly-color;
    arrachementAnomalyColor: $arrachement-anomaly-color;
    desenrobagePlumageAnomalyColor: $desenrobage_plumage-anomaly-color;
    emergenceSurChausseeAnomalyColor: $emergence_sur_chaussee-anomaly-color;
    faiençageAnomalyColor: $faiençage-anomaly-color;
    fissurationMultidirectionnelleAnomalyColor: $fissuration_multidirectionnelle-anomaly-color;
    faiençagePonteAnomalyColor: $faiençage_ponte-anomaly-color;
    fissMultiPonteAnomalyColor: $fiss_multi_ponte-anomaly-color;
    fissureLongitudinaleAnomalyColor: $fissure_longitudinale-anomaly-color;
    fissLongPonteAnomalyColor: $fiss_long_ponte-anomaly-color;
    fissureTransversaleAnomalyColor: $fissure_transversale-anomaly-color;
    fissTransPonteAnomalyColor: $fiss_trans_ponte-anomaly-color;
    fissPonteAnomalyColor: $fiss_ponte-anomaly-color;
    jointLongitudinalAnomalyColor: $joint_longitudinal-anomaly-color;
    jointLongitudinalPonteAnomalyColor: $joint_longitudinal_ponte-anomaly-color;
    nidDePouleAnomalyColor: $nid_de_poule-anomaly-color;
    nidDePouleRepareAnomalyColor: $nid_de_poule_repare-anomaly-color;
    peignageAnomalyColor: $peignage-anomaly-color;
    peladeAnomalyColor: $pelade-anomaly-color;
    reparationAnomalyColor: $reparation-anomaly-color;
    reparationEnrobeAnomalyColor: $reparation_enrobe-anomaly-color;
    reparationPataAnomalyColor: $reparation_pata-anomaly-color;
    ressuageAnomalyColor: $ressuage-anomaly-color;
    trancheeAnomalyColor: $tranchee-anomaly-color;
    mixedAnomaliesColor: $mixed_anomalies_color;
    trancheeTransversaleAnomalyColor: $tranchee_transversale-anomaly-color;
    jointLongPonteAnomalyColor: $joint_long_ponte-anomaly-color;
    feuDeCirculationAnomalyColor: $feu_de_circulation-anomaly-color;
    passagePietonAnomalyColor: $passage_pieton-anomaly-color;
    pontageAnomalyColor: $pontage-anomaly-color;
    tamponAnomalyColor: $tampon-anomaly-color;
    markingContinuousSolid: $marking--continuous--solid;
    markingContinuousDashed: $marking--continuous--dashed;
    markingOnlyContinuousDashed: $marking-only--continuous--dashed;
    markingContinuousZigzag: $marking--continuous--zigzag;
    markingDiscreteArrowRight: $marking--discrete--arrow--right;
    markingDiscreteArrowLeft: $marking--discrete--arrow--left;
    markingDiscreteArrowSplitRightOrStraight: $marking--discrete--arrow--split-right-or-straight;
    markingDiscreteArrowSplitLeftOrStraight: $marking--discrete--arrow--split-left-or-straight;
    markingDiscreteArrowStraight: $marking--discrete--arrow--straight;
    markingDiscreteStopLine: $marking--discrete--stop-line;
    markingDiscreteGiveWayRow: $marking--discrete--give-way-row;
    markingDiscreteGiveWaySingle: $marking__discrete__give_way_single;
    markingDiscreteCrosswalkZebra: $marking--discrete--crosswalk-zebra;
    markingDiscreteSymbolBicycle: $marking--discrete--symbol--bicycle;
    markingDiscreteText: $marking--discrete--text;
    markingDiscreteHatchedChevron: $marking--discrete--hatched--chevron;
    markingDiscreteHatchedDiagonal: $marking--discrete--hatched--diagonal;
    complementaryDetectionColor: $complementary-detection-color;
    warningDetectionColor: $warning-detection-color;
    informationDetectionColor: $information-detection-color;
    regulatoryDetectionColor: $regulatory-detection-color;
}
