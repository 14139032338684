.programming-map-content {
    height: 100%;
    width: 55%;
    position: relative;

    .measure {
        position: absolute;
        bottom: 80px;
        right: 52px;
        background-color: white;
        border-radius: 5px;
        padding: 5px;
        z-index: 2;
        width: fit-content;
        text-align-last: justify;
    }

    .measure:empty {
        display: none;
    }

    .map-actions {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 56px;
        background-color: rgb(255,255,255, 0.3);
        z-index: 1;
    }

    #AzureMap {
        height: 100%;
        width: 100%;

        .hidden-accessible-element, .map-copyright {
            display: none;
        }

        .atlas-map-canvas-container {
            height: 100%;
        }
    }
}
